
  
   .icon-boxes {
  
 
  }
  
  @media (min-width: 100px) {
    .icon-boxes:before {
      content: "";
      position: absolute;
      bottom: 0.1;
      left: 0;
      right: 0;
      /* height: calc(50% + 10px); */
      background-color:#00669e;
    }
  }
  
  .icon-box {
    padding: 60px 30px;
    position: relative;
    overflow: hidden;
    background: #004971;
    box-shadow: 0 0 29px 0 rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    
    height: 100%;
    width: 100%;
    text-align: center;
  }
  
  .icon-box .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
  }
  
  .icon-box .title a {
    color: #fff;
    transition: 0.3s;
  }
  
  .icon-box .icon {
    margin-bottom: 20px;
    padding-top: 10px;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    font-size: 48px;
    line-height: 1;
    color: rgba(255, 255, 255, 0.6);
  }
  
   .icon-box:hover {
    background: #00669e;
  }
  
  .icon-box:hover .title a,
  .icon-box:hover .icon {
    color: #fff;
  }
 